export const dateFmt = (date: Date) => {
  let
    month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    h = "" + date.getHours(),
    m = "" + date.getMinutes(),
    s = "" + date.getSeconds()
  if (month.length < 2) {
    month = "0" + month
  }
  if (day.length < 2) {
    day = "0" + day
  }
  if (h.length < 2) {
    h = "0" + h
  }
  if (m.length < 2) {
    m = "0" + m
  }
  if (s.length < 2) {
    s = "0" + s
  }
  return [month, day].join("-") + "  " + [h, m, s].join(":")
}

export const randomData = (ev: number, date: any) => {
  let now = new Date(date).valueOf()
  return {
    name: now,
    value: [
      now,
      ev
    ]
  }
}

export const countdown = ( endDate: any ) => {
  const end = endDate.valueOf()
  const now = new Date().valueOf()
  const msec: any = end - now
  if (msec < 0) {
    return `Ended`
  }
  let day: any = parseInt((msec / 1000 / 60 / 60 / 24).toString())
  let hr: any = parseInt((msec / 1000 / 60 / 60 % 24).toString()) + day * 24
  let min: any = parseInt((msec / 1000 / 60 % 60).toString())
  // let sec: any = parseInt((msec / 1000 % 60).toString())
  hr = hr > 9 ? hr : "0" + hr
  min = min > 9 ? min : "0" + min
  // sec = sec > 9 ? sec : "0" + sec
  setTimeout(() => {
      countdown(endDate)
  }, 60000)
  return `${hr} H ${min} M`
}