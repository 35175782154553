// token配置
const token = {
  BPool: '0x272fea610efcf539508cbad465998c294f22735e',
};

export const Config = {
  bPoolTokenIn: '0x59BAAf16Eb688c589ca84fb0f16927C2501c45e8',
  bPoolTokenOut: '0x323a4fAbB3503B576e60906EB68c5bf8A662F4FF',
};

export const projectConfig = {
  'status': 0,
  'endDate': '2021/7/10 14:54 UTC+8',
  'showEchartsTime': 180000000,
  'endBlockNum': 26025186,
  'startBlockNum': 26012386,
}

export const bpoolTOken = token.BPool;
