import {useEffect, useState} from 'react'
import Web3 from 'web3'
import bPOOL from '../constants/abi/bpool'
import {bpoolTOken, Config} from '../constants/config'

const web3: Web3 = new Web3(Web3.givenProvider)

// @ts-ignore
const bPOOLContract = new web3.eth.Contract(bPOOL, bpoolTOken)
export const usePrice = (address: string) => {
  const [price, setPrice] = useState(0)
  const getSpotPrice = async () => {
    const bb = await bPOOLContract.methods.getSpotPrice(Config.bPoolTokenIn, Config.bPoolTokenOut).call()
    setPrice(Number(bb / 1e6))
  }

  useEffect(() => {
    getSpotPrice()
  })

  return price
}
