import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const useHistoryPrice = () => {
  const pools = gql`
      {
        historicalPrices(first: 1000, skip: 0, where: {blockNum_lt: 26025186}) {
          id
          ts
          price
          blockNum
          weiA
          weiB
          deWeiA
          deWeiB
          balA
          balB
        }
      }
  `
  const {loading, error, data} = useQuery(pools)
  if (error) {
    return {
      data: undefined,
      error: true,
      loading: false,
    }
  }
  if (loading && !data) {
    return {
      data: undefined,
      error: false,
      loading: true,
    }
  }
  return { swaps: data.historicalPrices, error: false, loading: false }
}