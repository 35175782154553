import {
  ApolloClient,
  InMemoryCache
} from "@apollo/client"
export const client = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer',
  // uri: 'https://api.thegraph.com/subgraphs/name/alexis-eth/lbp',
  uri: 'https://api.thegraph.com/subgraphs/name/benjamin-l-bc/kovan_balance_p',
  // uri: 'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-kovan-v2',
  cache: new InMemoryCache()
});