import './App.css';
import Chart from '../components/Chart'
import styled from "styled-components";
import '../constants/erc20'
import { usePrice } from '../hooks/usePrice'
import { useHistoryPrice } from '../hooks/useHistoryPrice'
import { countdown } from '../utils/'
import { projectConfig } from '../constants/config'
import lbPic from '../assets/images/global_bottom1.png'
import rbPic from '../assets/images/global_bg2.png'

const Title = styled.h3`
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  margin: 10px 0 30px;
  letter-spacing: 1px;
`
const Wrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(12,minmax(0,1fr));
  max-width: 1024px;
  margin: 0 auto;
  > div: first-child {
    min-height: 360px;
    max-width: 700px;
  }
  > div: last-child {
    width: 336px;
  }
`
const Card = styled.div`
  height: 187px;
  // background: rgba(24, 31, 43, 0.5);
  border-radius: 6px;
  
  margin-bottom: 25px;
  padding-top: 35px;
  line-height: 20px;
  letter-spacing: 1px;
  border: 1px double transparent;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#0d1f2f, #0d1f2f),linear-gradient(180deg, #353848, #6A708B)!important;
  background-origin: border-box;
`
const Text = styled.p`
  font-size: 14px;
  color: #fff;
  margin-top: 16px;
`
const CardTitle = styled.h6`
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 5px;
  > span {
    font-size: 12px;
    font-weight: 400;
  }
`
const Buy = styled.button`
  padding: 10px 48px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  border: 0;
  outline: none;
  margin-top: 40px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #fff;
  height: 41px;
  box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  &:hover {
    background: linear-gradient(to right, #06DFAF, #0186EF);
    box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  }
`
const LinkWrapper = styled.div`
  max-width: 1024px;
  margin: 30px auto;
`
const Link = styled.a`
  height: 41px;
  border: 1px solid #6A708B;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 41px;
  margin-top: 8px;
  border-radius: 6px;
`
const Logo = styled.div`
  text-align: left;
  max-width: 1024px;
  margin: 30px auto;
`
const ChartRemarks = styled.div`
  font-weight: 500;
  color: #69708D;
  line-height: 15px;
  font-size: 12px;
  text-align: left;
  max-width: 1024px;
  margin: 0px auto;
  > p: first-child, > p: last-child {
    &::before {
      display: inline-block;
      content: '';
      width: 50px;
      position: relative;
      top: -3px;
      margin-right: 10px;
    }
  }
  > p: first-child {
    &::before {
      background: linear-gradient(90deg,#3F70FF,#06DFAF);
      height: 2px;
    }
  }
  > p: last-child {
    &::before {
      border-bottom: 2px dashed #fff;
    }
  }
`
const LFireWrapper = styled.div`
  position: absolute;
  zIndex: -1;
  left: 0;
  bottom: 0;
  text-align: left;
  img {
    margin-bottom: -40px
  }
`
const RFireWrapper = styled.div`
  position: absolute;
  zIndex: -1;
  right: 0;
  bottom: 0;
  text-align: right;
  img {
    margin-bottom: -40px
  }
`
function App() {
  const price = usePrice()
  const historyPrice = useHistoryPrice()
  return (
    <div className="App">
      <LFireWrapper>
        <img width="50%" src={lbPic} alt=""/>
      </LFireWrapper>
      <RFireWrapper>
        <img width="30%" src={rbPic} alt=""/>
      </RFireWrapper>
      <Logo>
        <img width="180px" src={require('../assets/images/logo.svg').default} alt="icon"/>
      </Logo>
      <Title>DIVER LIQUIDITY BOOTSTRAPPING POOL</Title>
      <Wrapper>
        <Chart historyPrice={historyPrice.swaps}/>
        <div>
          <Card>
            <img width="53px" src={require('../assets/images/time.png').default} alt="icon"/>
            <Text>LBP END IN</Text>
            <CardTitle>{countdown(new Date(projectConfig.endDate))}</CardTitle>
          </Card>
          <Card>
            <img width="53px" src={require('../assets/images/price.png').default} alt="icon"/>
            <Text>LATEST PRICE</Text>
            <CardTitle>{price.toFixed(3)} <span> USDC</span></CardTitle>
          </Card>
        </div>
      </Wrapper>
      <ChartRemarks>
        <p>The solid line illustrates the history of DIVER LBP Price</p>
        <p>The dashed line represents the future price if no one buys DIVER</p>
      </ChartRemarks>
      <Buy>
        Buy DIVER
      </Buy>
      <LinkWrapper>
        <Link style={{width: '407px', margin: '10px auto'}}>Introducing Divergence</Link>
        <Link style={{width: '336px', margin: '10px auto'}}>DIVER token economics</Link>
        <Link style={{width: '432px', margin: '10px auto'}}>Divergence Balancer LBP sheet</Link>
        <Link style={{width: '463px', margin: '10px auto'}}>Balancer LBP participation guide</Link>
      </LinkWrapper>
      
    </div>
  );
}

export default App;
