// @ts-nocheck
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { projectConfig } from '../../constants/config'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LineChart, BarChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
import {
  CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, LineChart, BarChart, CanvasRenderer]
)
dayjs.extend(utc)

const randomData = (price: number, date: any) => {
	return {
		blockNum: date,
		price: price
	};
}

const Chart = (props: {historyPrice: any}) => {
	const [date, setDate] = useState()
	const [price, setPrice] = useState()
	const [endList, setEndList] = useState()
	const [endListPrice, setEndListPrice] = useState()
	useEffect(() => {
		
		if (props.historyPrice !== undefined && props.historyPrice.length > 0) {
			setPrice(props.historyPrice.map((i: any) => {
				return (i.price)
			}))
			const historyPriceEnd = props.historyPrice[props.historyPrice.length - 1]
			let balanceA = Number(historyPriceEnd.balA)
			let balanceB = Number(historyPriceEnd.balB)
			let weightA = Number(historyPriceEnd.weiA)
			let weightB = Number(historyPriceEnd.weiB)
			let _tmpPrice: number = historyPriceEnd.price
			let _initPrice: number = historyPriceEnd.price
			const endList = [{
				price: _tmpPrice,
				blockNum: historyPriceEnd.blockNum
			}]
			const surplus = (Number(historyPriceEnd.blockNum) - Number(projectConfig.startBlockNum)) % 178
			let _blockNum = 178 - surplus + Number(historyPriceEnd.blockNum)
			weightA = weightA + (178 - surplus) / 178 * 0.0052734375000000056
			weightB = 1 - weightA
			_tmpPrice = (balanceA / weightA) / (balanceB / weightB)
			endList.push({
				price: _tmpPrice.toFixed(6),
				blockNum: _blockNum
			})
			for (let i = 0; i < 72; i++) {
				weightA += 0.0052734375000000056   // every min
				weightB = 1 - weightA
				_tmpPrice = (balanceA / weightA) / (balanceB / weightB)
				if (_tmpPrice < _initPrice) {
					_blockNum = Number(_blockNum) + 178
					endList.push(randomData(_tmpPrice.toFixed(6), _blockNum))
				}
				if (_blockNum > projectConfig.endBlockNum - 178) {
					break
				} else {
					
				}
			}
			// console.log(endList)
			setEndList(endList)
		}
	}, [props])
	useEffect(() => {
		if (props.historyPrice !== undefined && props.historyPrice.length > 0) {
			let _dataArr = props.historyPrice.map((i: any) => {
				return i.blockNum
			})
			let _endDataArr = []
			if (endList && endList.length > 0) {
				_endDataArr = endList.map((i: any) => {
					return i.blockNum
				})
				const endListArr = endList.map((i: any) => {
					return i.price
				})
				const _un = props.historyPrice.map((i: any) => {
					return null
				})
				_un.pop()
				const _arr = _un.concat(endListArr)
				setEndListPrice(_arr)
			}
			const _setArr = _dataArr.concat(_endDataArr)
			setDate(_setArr)
		}
	}, [props, endList])
  const option = {
    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    title: {
			text: ''
    },
    tooltip: {
			trigger: 'axis',
			backgroundColor: 'rgba(0, 0, 0, 0.6)',
			borderColor: 'rgba(0, 0, 0, 0)',
			textStyle: {
				color: '#fff'
			},
			axisPointer: {
				type: 'line',
				label: {
					backgroundColor: '#6a7985',
					formatter: function (params) {
						return params.value;
					}
				}
			},
			formatter: (params: any) => {
				const _data = props.historyPrice[params[0].dataIndex]
				if (_data) {
					return `<div>
						<p>$${_data && _data.price}<p>
						<p>#${_data && _data.blockNum}</p>
					</div>`
				} else {
					return `<div>
						<p>$${Number(params[0].value)}<p>
						<p>#${date && date[params[0].dataIndex+1]}</p>
					</div>`
				}
				
				
			}
    },
    grid: {
			left: '7%',
			right: '1%',
			bottom: '3%',
			containLabel: true
    },
    xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				axisLabel: {
					formatter: function (value, index) {
						return '#' + value;
					},
					// interval: 10,
					show: true,
					 textStyle: {
						 color: '#6A708B',  //更改坐标轴文字颜色
						 fontSize : 12      //更改坐标轴文字大小
					 }
				},
				splitNumber: 20000,
				axisTick: {
					show: false
				},
				axisLine:{
					show: false
				},
				data: date
			}
    ],
    yAxis: [
			{
				position: 'right',
				type: 'value',
				axisLabel: {
					show: true,
					textStyle: {
						color: '#6A708B',  //更改坐标轴文字颜色
						fontSize : 12      //更改坐标轴文字大小
					}
				},
				splitLine: {
					show: false
				}
			}
    ],
    series: [
			{
				name: 'Line 1',
				type: 'line',
				stack: 'Actual',
				// smooth: true,
				lineStyle: {
					width: 2,
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: '#3F70FF'
					}, {
						offset: 1,
						color: '#06DFAF'
					}])
				},
				showSymbol: false,
				areaStyle: {
					opacity: 0.8,
					color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
						offset: 0,
						color: 'rgba(6, 223, 175, 0.5)'
					}, {
						offset: 1,
						color: '#0186EF'
					}])
				},
				emphasis: {
					focus: 'series'
				},
				data: price,
				connectNulls: true,
				clip: true
			},
			{
				name: 'Line 2',
				type: 'line',
				stack: 'Future',
				smooth: false,
				lineStyle: {
					width: 1,
					type: [5, 10],
					color: '#fff'
				},
				showSymbol: false,
				data: endListPrice,
				connectNulls: true,
				clip: true
			}
    ]
}
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
    />
  )
}
export default Chart